.body {
  width: 100vw;
  height: 100vh;
  background-color: #F3DC1E;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999999999999;
}

.image {
  width: 100%;
  max-width: 190px;
  padding: 0px 50px;
  pointer-events: none;
}
