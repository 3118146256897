.tapeBody {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 900px;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  composes: colorLightBorder from './../../../../styles/color.module.css';
}

.overflow {
  box-sizing: border-box;
  padding: 20px 24px;
  composes: colorLightBackground from './../../../../styles/color.module.css';
}

@media (max-width: 640px) {
  .tapeBody {
    border-radius: 8px;
  }

  .overflow {
    padding: 15px 19px;
  }
}
