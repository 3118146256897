.body {
  width: 100%;
  max-width: 938px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
}

.icon {
  width: 15px;
  height: 14px;
  margin-right: 6px;
}

.bodyLink {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  margin-right: 3px;
  width: fit-content;
  min-width: fit-content;
  margin-left: auto;
}

.image {
  width: 20%;
  min-width: 20%;
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  background-color: #f3dc1d;
}

.row {
  width: 100%;
}

@media (min-width: 938px) {
  .row {
    width: auto;
  }
}
