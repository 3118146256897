.body {
  width: 100%;
  max-width: 938px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.overflow {
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: flex-end;
}

.icon {
  width: 15px;
  height: 12px;
  margin-left: 6px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  width: 275px;
  margin-right: 3px;
}
