.body {
  width: 100%;
  max-width: 938px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.wrapper {
  width: 100%;
  max-width: 900px;
  height: 20vw;
  min-height: 100px;
  max-height: 150px;
  display: flex;
  justify-content: center;
  aling-items: center;
  position: relative;
}
