.defaultConsole {
  border: 1px solid #E4E4E4;
  background: #fff;
  box-shadow: 0px 7px 40px #908f8f7d;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  max-width: 700px;
  min-width: 300px;
  display: flex;
  position: relative;
}

.console {
  box-shadow: 0px 7px 40px #908f8f7d;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  max-width: 700px;
  min-width: 300px;
  display: flex;
  position: relative;
  border: 1px solid #a2a2a2;
  background-color: #000;
}

@media (max-width: 640px) {
  .defaultConsole {
    border-radius: 8px;
  }

  .console {
    border-radius: 8px;
  }
}
