.body {
  padding: 18px 30px 38px 30px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.wrapper {
  width: 100%;
  max-width: 938px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.titleMarginTop {
  margin-top: 50px;
}

.titleH1 {
  width: 100%;
  max-width: 900px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 27px;
  margin-top: 35px;
  margin-bottom: 13px;
}

.titleH2 {
  width: 100%;
  max-width: 900px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  margin-top: 27px;
  margin-bottom: 10px;
}

.buttonLink {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.1px;
}

.description {
  width: 100%;
  max-width: 900px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.1px;
  margin-bottom: 12px;
  margin-top: 0px;
}

.textSelect {
  padding: 2px 0px;
  max-width: 900px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.1px;
  margin-bottom: 12px;
  margin-top: 0px;
}

.quote {
  width: 100%;
  max-width: 900px;
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 12px;
  margin-top: 0px;
  border-left: 5px solid #f3dc1d;
  padding-left: 15px;
  box-sizing: border-box;
}

.image {
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center center;
  margin-top: 5px;
  margin-bottom: 15px;
  pointer-events: none;
}

@media (max-width: 1200px) {
  .body {
    padding: 18px 20px 36px 20px;
    width: calc(100% - 82px);
  }

  .titleMarginTop {
    margin-top: 20px;
  }
}

@media (max-width: 640px) {
  .body {
    padding: 18px 15px 20px 15px;
    width: calc(100% - 50px);
  }

  .titleH1 {
    font-size: 27px;
    line-height: 34px;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  .titleH2 {
    font-size: 19px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .description,.quote,.textSelect {
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
    margin-top: 4px;
  }

  .image {
    border-radius: 8px;
  }
}
