.body {
  width: 100%;
  max-width: 920px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0px 10px;
  position: relative;
}

.wrapper {
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
}

.column {
  width: 20%;
  min-width: 20%;
  box-sizing: border-box;
  padding-right: 7.5px;
  padding-left: 7.5px;
  overflow: hidden;
  font-size: 0px;
}

.column:first-child {
  padding-left: 0px;
}

.column:last-child {
  padding-right: 0px;
}

@media (max-width: 800px) {
  .column {
    width: 50%;
    min-width: 50%;
    padding-right: 5px;
    padding-left: 5px;
  }

  .column:nth-child(3) {
    display: none;
  }

  .column:nth-child(4) {
    display: none;
  }

  .column:nth-child(5) {
    display: none;
  }
}
