.wrapper {
  width: 100%;
  max-width: 938px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.titleH1 {
  composes: colorDarkTitle from './../../styles/color.module.css';
  composes: titleH1 from './main.module.css';
}

.titleH2 {
  composes: colorDarkTitle from './../../styles/color.module.css';
  composes: titleH2 from './main.module.css';
}

.image {
  composes: colorDarkBorder from './../../styles/color.module.css';
}

.buttonLink {
  composes: colorDarkButtonLink from './../../styles/color.module.css';
  composes: buttonLink from './main.module.css';
}

.description,.link {
  composes: colorDarkDescription from './../../styles/color.module.css';
  composes: description from './main.module.css';
}

.quote {
  composes: colorDarkDescription from './../../styles/color.module.css';
  composes: quote from './main.module.css';
}

.textSelect {
  composes: colorDarkSelect from './../../styles/color.module.css';
  composes: textSelect from './main.module.css';
}

.titleMarginTop {
  margin-top: 50px;
}

@media (max-width: 1200px) {
  .titleMarginTop {
    margin-top: 20px;
  }
}
