.colorLightBackground {
  background: #fff;
}

.colorDarkBackground {
  background: #121212;
}

.colorDarkTitle {
  color: #e8e8e8;
}

.colorLightTitle {
  color: #303030;
}

.colorDarkButtonLink {
  color: #cfcfcf;
  fill: #cfcfcf;
  stroke: #cfcfcf;
}

.colorLightButtonLink {
  color: #3e3e3e;
  fill: #3e3e3e;
  stroke: #3e3e3e;
}

.colorDarkDescription {
  color: #d2d2d2;
}

.colorLightDescription {
  color: #4a4a4a;
}

.colorDarkSelect {
  background: #f3dc1e;
  color: #0B0B0B;
}

.colorLightSelect {
  background: #f3dc1e;
  color: #4a4a4a;
}

.colorDarkBorder {
  box-shadow: 0px 2px 10px rgb(46 46 46 / 7%);
  border: 1px solid #1a1a1a;
}

.colorLightBorder {
  box-shadow: 0px 4px 12px 0px #e2e2e20f;
  border: 1px solid #93939312;
}
