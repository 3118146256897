.tapeBody {
  font-size: 0px;
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 900px;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  display: flex;
  composes: colorLightBackground from './../../../../styles/color.module.css';
  composes: colorLightBorder from './../../../../styles/color.module.css';
}

.link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkImg {
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 15px;
  width: 100%;
  cursor: pointer;
  composes: colorLightBackground from './../../../../styles/color.module.css';
}

.linkOverflov {
  border-radius: 10px;
  width: 100%;
  height: calc(100% - 15px);
  background: #f3dc1d;
  color: #000;
  position: absolute;
  top: 0px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  composes: colorLight from './../../../../styles/color.module.css';
}

@media (max-width: 640px) {
  .linkImg {
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .linkOverflov {
    border-radius: 8px;
    height: calc(100% - 10px);
  }
}
