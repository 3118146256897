.tapeBody {
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  max-width: max-content;
  margin-bottom: 20px;
  margin-right: 20px;
  display: flex;
  composes: colorDarkBorder from './../../../styles/color.module.css';
}

.overflow {
  box-sizing: border-box;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  composes: colorDarkBackground from './../../../styles/color.module.css';
}

.sectionTitle {
  composes: description from './../../../styles/color.module.css';
  composes: colorDarkTitle from './../../../styles/color.module.css';
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 640px) {
  .tapeBody {
    border-radius: 8px;
  }

  .overflow {
    padding: 15px 19px;
  }
}
