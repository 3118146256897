.body {
  width: 100%;
  max-width: 938px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.wrapper {
  width: 100%;
  max-width: 900px;
  height: 500px;
  display: flex;
  justify-content: center;
  aling-items: center;
  position: relative;
}

.darkBackground {
  background-color: #000000bf;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 43px;
}

.overflow {
  left: 0px;
  top: 43px;
  position: absolute;
  box-sizing: border-box;
  background-color: #000000bf;
  color: #fff;
  padding: 0px 0px 0px 13px;
  margin-right: 13px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  min-width: 300px;
  overflow: auto;
  height: calc(100% - 86px);
}

.circle {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 12px;
  z-index: 9999;
  cursor: pointer;
}

.textRow {
  font-family: Roboto;
  font-style: normal;
  font-size: 15px;
  line-height: 25px;
  height: 20px;
  min-height: 20px;
  width: fit-content;
  max-width: 900px;
  min-width: fit-content;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.input {
  width: 100%;
  border: none;
  background: #00000000;
  border-radius: 0px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-size: 15px;
  line-height: 25px;
  height: 20px;
  min-height: 20px;
  padding: 0px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.formBody {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffc8f6;
  box-sizing: border-box;
  padding: 0px 7vw;
}

.formInput {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  width: 100%;
  max-width: 900px;
  letter-spacing: 0.1px;
  width: 100%;
  max-width: 900px;
  color: #d69ccd;
  margin-bottom: 16px;
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 10px;
  background: #feeeff;
}

.formMessage {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  width: 100%;
  max-width: 900px;
  letter-spacing: 0.1px;
  width: 100%;
  max-width: 900px;
  color: #d69ccd;
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 10px;
  resize: none;
  height: 125px;
  background: #feeeff;
}

.formSubmit {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  width: 100%;
  max-width: 900px;
  letter-spacing: 0.1px;
  width: 100%;
  max-width: 900px;
  color: #d69ccd;
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 10px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-top: 20px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  background: #feeeff;
}

@media (max-width: 640px) {
  .overflow {
    padding: 15px 19px;
  }

  .textRow {
    font-size: 15px;
    line-height: 21px;
  }

  .input {
    font-size: 15px;
    line-height: 21px;
  }

  .formInput {
    font-size: 15px;
    line-height: 21px;
  }

  .formMessage {
    font-size: 15px;
    line-height: 21px;
  }

  .formSubmit {
    font-size: 15px;
    line-height: 21px;
  }
}
