.footer {
  margin: 0px 50%;
  width: max-content;
  margin-top: 20px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cacaca;
}

.heartOverflow {
  width: 15px;
  min-width: 15px;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heart {
  width: 15px;
  min-width: 15px;
  position: absolute;
}
