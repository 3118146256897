.tapeBody {
  font-size: 0px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 900px;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  display: flex;
  composes: colorLightBorder from './../../../../styles/color.module.css';
}

.overflow {
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 24px;
  composes: colorLightBorder from './../../../../styles/color.module.css';
  composes: colorLightBackground from './../../../../styles/color.module.css';
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
}

@media (min-width: 938px) {
  .tapeBody {
    width: calc(450px - 10px);
    max-width: calc(450px - 10px);
  }
}

@media (max-width: 640px) {
  .tapeBody {
    border-radius: 8px;
  }

  .overflow {
    padding: 15px 19px;
  }
}
