.radio {
  box-shadow: 0px 7px 40px #908f8f7d;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  display: flex;
  position: relative;
  border: 1px solid #a2a2a2;
  background-color: #000;
}

@media (max-width: 640px) {
  .radio {
    border-radius: 8px;
  }
}
