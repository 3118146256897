.body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #e8e8e8;
}

.overflow {
  width: 58px;
  height: 29px;
  background: #e8e8e8;
  border-radius: 21px;
  display: flex;
  align-items: center;
  position: relative;
  background: #fdfdfd;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  position: absolute;
  composes: colorDarkBackground from './../../styles/color.module.css';
}

@media (max-width: 1200px) {
  .body {
    margin-bottom: 20px;
    justify-content: center;
  }

  .text {
    display: none;
  }
}

@media (max-width: 640px) {
  .body {
    margin-bottom: 15px;
  }

  .overflow {
    width: 29px;
    height: 14.5px;
  }

  .circle {
    width: 7.5px;
    height: 7.5px;
  }
}
