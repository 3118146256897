.body {
  width: 100%;
  max-width: 938px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
}

.icon {
  width: 15px;
  height: 14px;
  margin-right: 6px;
}

.bodyLink {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  margin-right: 3px;
  width: fit-content;
  min-width: fit-content;
  margin-left: auto;
}

.image {
  width: 100%;
  min-width: 100%;
  padding: 5vh 5vh 20vh 0vh;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: #f3dc1d;
}

@media (max-width: 938px) {
  .image {
    width: 100%;
    min-width: 100%;
    height: 40vh;
    padding: 0px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
  }
}
