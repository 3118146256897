.tag {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.1px;
  margin-left: 5px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  display: inline-block;
  padding: 0px 2px;
  composes: colorLightSelect from './../../../styles/color.module.css';
}

@media (max-width: 640px) {
  .tag {
    font-size: 15px;
    line-height: 21px;
  }
}
