.body {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: unset;
  min-height: 100vh;
  background: #111111;
  box-shadow: 0px 0px 2px #66666699;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 22px;
  box-sizing: border-box;
  opacity: 0;
}

.avatar {
  width: 120px;
  height: 120px;
  margin-top: 30px;
  border-radius: 100px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.name {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-top: 21px;
  width: auto;
  margin-bottom: 0px;
  composes: colorDarkTitle from './../styles/color.module.css';
}

.description {
  margin-top: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  composes: colorDarkDescription from './../styles/color.module.css';
}

.line {
  width: 100%;
  height: 1px;
  min-height: 1px;
  background: #343434;
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.navigation {
  width: 100%;
}

.button {
  position: relative;
  margin-top: 20px;
}

.link {
  height: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  text-decoration: none;
}

.linkIcon {
  width: 25px;
  height: 25px;
}

.linkText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1px;
  line-height: 20.5px;
  margin-left: 10px;
  composes: colorDarkTitle from './../styles/color.module.css';
}

.miniText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 20px;
  margin-top: 0px;
  color: #8E8E8E;
}

.miniTextInverse {
  color: rgb(213 213 213);
}

.hintBody {
  text-decoration: none;
  position: absolute;
  z-index: 9999;
  display: none;
  width: max-content;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0);
}

.hintText {
  padding: 9px 17px 9px 17px;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.005em;
  margin-left: -13px;
  box-shadow: 10px 3px 45px rgb(71 71 71 / 52%);
  user-select: none;
  cursor: pointer;
  composes: colorLightTitle from './../styles/color.module.css';
  composes: colorLightBackground from './../styles/color.module.css';
}

.hintIcons {
  position: relative;
  z-index: 9999;
}

@media (max-width: 1200px) {
  .body {
    width: 82px;
    min-width: 82px;
    max-width: 82px;
    height: unset;
    box-shadow: 0px 0px 2px #66666688;
    display: flex;
    padding: 0px 12px;
  }

  .avatar {
    width: 50px;
    height: 50px;
  }

  .name {
    transform: rotate(90deg);
    width: 192px;
    margin-top: 99px;
    margin-bottom: 80px;
  }

  .description {
    display: none;
  }

  .navigation {
    width: auto;
  }

  .linkText {
    display: none;
  }

  .lineEnd {
    display: none;
  }

  .miniText {
    display: none;
  }

  .hintBody {
    display: flex;
    left: 42px;
    height: 25px;
    margin-top: -25px;
  }

  .hintIcons {
    height: 25px;
    width: 25px;
  }
}

@media (max-width: 640px) {
  .body {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    padding: 0px 10px;
    box-shadow: 0px 0px 1px #66666699;
  }

  .avatar {
    width: 30px;
    height: 30px;
    margin-top: 20px;
  }

  .name {
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    transform: rotate(90deg);
    width: 128px;
    margin-top: 65px;
    margin-bottom: 53px;
  }

  .line {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .link {
    height: 20px;
    min-height: 20px;
    margin-top: 15px;
  }

  .linkIcon {
    width: 20px;
    height: 20px;
  }

  .linkText {
    display: none;
  }

  .hintBody {
    display: flex;
    left: 26px;
    height: 20px;
    margin-top: -20px;
  }

  .hintText {
    margin-left: -10px;
  }

  .hintIcons {
    height: 20px;
    width: 20px;
  }
}
