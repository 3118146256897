.body {
  width: 100%;
  max-width: 938px;
  height: 435px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
}

.wrapper {
  width: 100%;
  height: 435px;
  max-width: 900px;
  display: flex;
  justify-content: center;
  position: relative;
}

.column {
  width: 20%;
  min-width: 20%;
  box-sizing: border-box;
  padding-right: 7.5px;
  padding-left: 7.5px;
  overflow: hidden;
  font-size: 0px;
}

@media (max-width: 800px) {
  .column {
    width: 33.33%;
    min-width: 33.33%;
    padding-right: 5px;
    padding-left: 5px;
  }

  .body {
    height: 335px;
  }

  .wrapper {
    height: 335px;
  }
}
