.body {
  width: 100%;
  max-width: 938px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  margin-bottom: -20px;
}

.wrapper {
  width: 100%;
  max-width: 900px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  display: flex;
  flex-wrap: wrap;
}

.overflowText {
  composes: description from './../../../styles/color.module.css';
  margin-top: 0px;
  margin-bottom: 0px;
}

.link {
  cursor: pointer;
  margin-left: 2px;
}
